const jobRoute = [
    {
        path: '/findjob/jobs.htm',
        name: 'findJob',
        meta: { isAuth: true},
        component: () => import('../../views/wx/findjob/jobs.vue'),
    },
    {
        path: '/findjob/jobdetail.htm',
        name: 'jobDetail',
        meta: { 
            isAuth: true,
            title: '岗位详情' 
        },
        component: () => import('../../views/wx/findjob/jobdetail.vue'),
    },
    {
        path: '/findjob/corp.htm',
        name: 'Corp',
        meta: { isAuth: true},
        component: () => import('../../views/wx/findjob/corp.vue'),
    }
]

export default jobRoute