// 引入微信js-sdk
import wx from 'weixin-js-sdk';
import { wxTimelineShare } from "@/network/comn.js";

// 提取公共部分到常量
const ua = navigator.userAgent.toLowerCase();

/**
 * 检测是否在微信浏览器环境中
 * @returns {boolean} 如果用户代理字符串包含 'micromessenger'，返回 true，否则返回 false。
 */
export function isInWx() {
    return ua.includes('micromessenger');
}

/**
 * 检测是否在微信小程序环境中
 * @returns {boolean} 如果用户代理字符串包含 'miniprogram'，返回 true，否则返回 false。
 */
export function isInWxMp() {
    return ua.includes('miniprogram');
}

/**
 * 配置微信 JS SDK 并设置分享功能
 * @param {Object} config - 微信的配置参数
 * @param {string} title - 分享标题
 * @param {string} desc - 分享描述
 * @param {string} imgUrl - 分享图标
 * @param {Function} wxTimelineShare - 分享到朋友圈的回调函数
 * @param {string} page - 当前页面
 * @param {boolean} [debug=false] - 是否开启调试模式
 * @param {Array} [jsApiList=[]] - 需要使用的 JS API 列表
 * @param {Array} [openTagList=[]] - 需要使用的标签列表
 */
export function readyWxJsSdk(config, title, desc, imgUrl, page, debug = false, reload = true, jsApiList = [], openTagList = []) {
    return new Promise((resolve, reject) => {
        // 配置微信 JS SDK
        wx.config({
            debug: debug,
            appId: config.appid,
            timestamp: config.timestamp,
            nonceStr: config.noncestr,
            signature: config.signature,
            jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline', ...jsApiList],
            openTagList: ['wx-open-launch-weapp', ...openTagList]
        });

        // 获取 jssdk 成功
        wx.ready(function () {

            // 设置分享链接
            const urlObj = new URL(window.location.href);
            urlObj.searchParams.set('wx_openid', window.localStorage.getItem('wx_openid'));
            const link = urlObj.toString();

            // 分享到朋友
            wx.onMenuShareAppMessage({
                title,
                desc,
                link,
                imgUrl,
                success: function () {
                    // 成功回调
                    console.log('分享成功');
                }
            });

            // 分享到朋友圈
            wx.onMenuShareTimeline({
                title,
                link,
                imgUrl,
                success: function () {
                    wxTimelineShare({ page, wx_openid: window.localStorage.getItem('wx_openid') }).then((res) => {
                        if (res) {
                            console.log('朋友圈分享成功', res);
                        }
                    });
                }
            });

            // 配置成功，返回 true
            resolve(true);
        });

        // 配置错误回调
        wx.error(function (res) {
            console.error('微信 SDK 错误:', res);
            // 重新刷新页面
            if (reload) {
                window.location.reload();
            }
            // reject(false);
        });
    })
}

// 组装auth2地址
export function readyWxAuthUrl(appid, state, redirectedFrom) {
    const scope = state ? 'snsapi_userinfo' : 'snsapi_base';
    const redirectUri = encodeURIComponent(`${window.location.origin}/wxLogin?redirectedFrom=${encodeURIComponent(redirectedFrom)}`);
    
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state ?? 0}#wechat_redirect`;
}


