const userRoute = [
    {
        path: '/user/basic.htm',
        name: 'userBasicinfo',
        meta: { isAuth: true },
        component: () => import('../../views/wx/user/basic.vue')
    },
    // {
    //     path: '/user/basic.htm',
    //     name: 'userBasicinfo',
    //     meta: { isAuth: true },
    //     component: () => import('../../views/wx/user/basic.vue')
    // },
    {
        path: '/user/resume.htm',
        name: 'userResume',
        meta: { isAuth: true },
        component: () => import('../../views/wx/user/resume.vue')
    },
    {
        path: '/user/test.htm',
        name: 'usertest',
        meta: { isAuth: true },
        component: () => import('../../views/wx/user/test.vue')
    }
]

export default userRoute