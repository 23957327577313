const comnRoute = [
    {
        path: '/msg_success.htm',
        name: 'msg_success',
        component: () => import('../../views/comn/success.vue'),
    },
    {
        path: '/msg_warn.htm',
        name: 'msg_warn',
        component: () => import('../../views/comn/warn.vue'),
    },
    {
        path: '/editpara.htm',
        name: 'editpara',
        // meta: { isAuth: true },
        component: () => import('../../views/comn/editpara.vue')
    },
    {
        path: '/editphra.htm',
        name: 'editphra',
        // meta: { isAuth: true },
        component: () => import('../../views/comn/editphra.vue')
    },
    {
        path: '/csl/upload.htm',
        name: 'upload',
        // meta: { isAuth: true },
        component: () => import('../../views/comn/upload.vue')
    }
]

export default comnRoute