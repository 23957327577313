import { createRouter, createWebHistory } from 'vue-router';
import comnRoute from './comn/comn.js';
import { isInWx, readyWxAuthUrl } from '@/components/wxui/wx/index.js';
import { loadUser } from "@/network/wx/user.js";
import { loadWxMpAc } from "@/network/comn.js";

import examRoute from './wx/exam.js';
import jobRoute from './wx/findjob.js';
import campusRoute from './wx/campus.js';
import gameRoute from './wx/game.js';
import userRoute from './wx/user.js';
import attnRoute from './wx/attn.js';
import useCarRoute from './wx/car.js';
import shop from './wx/shop.js';

import cslExamRoute from './csl/exam.js';
import cslAttnRoute from './csl/attn.js';
import cslStaffsRoute from './csl/staffs.js';
import cslUserRoute from './csl/user.js';
import cslGameRoute from './csl/game.js';
import cslUseCarRoute from './csl/usercar.js';

const routes = [
    {
        path: '/wxLogin',
        name: 'wxLogin',
        component: () => import('../views/wxLogin.vue'),
    },
    {
        path: '/wxOnly',
        name: 'wxOnly',
        component: () => import('../views/wxOnly.vue'),
    },
    {
        path: '/',
        name: 'index',
        alias: '/',
        // meta: { isAuth: true },
        component: () => import('../views/index.vue'),
    },
    {
        path: '/wx/index.htm',
        name: 'wxIndex',
        meta: { isAuth: true, state: 1 },
        component: () => import('../views/wx/index.vue'),
    },
    {
        path: '/csl/index.htm',
        name: 'cslIndex',
        // meta: { isAuth: true },
        component: () => import('../views/csl/index.vue'),
    },

    ...comnRoute,
    ...examRoute,
    ...jobRoute,
    ...campusRoute,
    ...gameRoute,
    ...userRoute,
    ...attnRoute,
    ...useCarRoute,
    ...shop,

    // 后台管理
    ...cslExamRoute,
    ...cslAttnRoute,
    ...cslStaffsRoute,
    ...cslUserRoute,
    ...cslGameRoute,
    ...cslUseCarRoute

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    // 更新页面标题
    window.document.title = to.meta.title || window.document.title;

    // 如果需要认证
    if (to.meta.isAuth) {
        const wx_openid = window.localStorage.getItem('wx_openid');

        const wxMpAc = await loadWxMpAc();
        const authUrl = readyWxAuthUrl(wxMpAc.appid, to.meta.state, to.fullPath);

        if (isInWx() && wx_openid) {
            // 查询用户信息
            const res = await loadUser({ wx_openid });

            if (!res.wx_unionid && to.meta.state > 0) {
                // 没有 unionid，重定向到微信授权页面
                window.location.href = authUrl;
                return; // 确保不继续调用 next()
            }

            // 有 wx_unionid 或不需要 unionid 的情况，继续导航
            next();
        } else if (isInWx() && !wx_openid) {
            // 用户未登录且在微信中，重定向到微信授权页面
            window.location.href = authUrl;
            return; // 确保不继续调用 next()
        } else {
            // 不在微信中打开，重定向到 /wxOnly
            next('/wxOnly');
        }

    } else {
        // 如果不需要认证或处理完所有逻辑，继续路由导航
        next();
    }
});

export default router
