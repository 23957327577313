const cslStaffsRoute = [
    {
        path: '/csl/staffs/basic.htm',
        name: 'cslStaffsBasic',
        component: () => import('../../views/csl/staffs/basic.vue'),
    },
    {
        path: '/csl/staffs/upload.htm',
        name: 'cslStaffsUpload',
        component: () => import('../../views/csl/staffs/upload.vue'),
    }
]

export default cslStaffsRoute