const shop = [
    {
        path: '/shop/index.htm',
        name: 'shopIndex',
        // meta: { isAuth: true, state: 1 },
        component: () => import('../../views/wx/shop/index.vue')
    },
    {
        path: '/shop/detail.htm',
        name: 'shopDetail',
        // meta: { isAuth: true, state: 1 },
        component: () => import('../../views/wx/shop/detail.vue')
    },
    {
        path: '/shop/exchange.htm',
        name: 'shopExchange',
        meta: { isAuth: true, state: 1 },
        component: () => import('../../views/wx/shop/exchange.vue')
    }
]

export default shop