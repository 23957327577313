const cslExamRoute = [
    {
        path: '/csl/exam/pdpfirst.htm',
        name: 'cslPdpFirst',
        component: () => import('../../views/csl/exam/pdp/pdpfirst.vue'),
    },
    {
        path: '/csl/exam/pdpall.htm',
        name: 'cslPdpall',
        component: () => import('../../views/csl/exam/pdp/pdpall.vue'),
    }
]

export default cslExamRoute