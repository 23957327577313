import tpl from './index.vue';
import { createApp } from 'vue';

let inst = null;

// 初始化用于挂载实例的节点
let node = null;

// 挂载节点函数
const onMount = (options) => {
    // 将导入的模板创建一个vue实例 并传递options参数
    inst = createApp(tpl, options);

    // 创建用于挂载实例的节点
    if(!node){
        node = document.createElement('div');
    }
    // 将创建的 Node 元素节点添加到页面的 <body> 中
    document.body.appendChild(node);
    // 将实例挂载到指定的 DOM 元素节点上（Node）
    inst.mount(node);
}

// 显示tost
const show = (content = '', duration = 2000, options = {}) => {

    options = {
        content,
        duration,
        icon: 'success',
        ...options
    };

    // 调用挂载节点函数
    onMount(options);

    // 显示一段时间后自动消失，默认2秒
    setTimeout(() => {
        inst.unmount(node);
        document.body.removeChild(node);
    }, duration);
}

// 显示tost-warn
const warn = (content = '', duration = 2000, options = {}) => {
    options = {
        content,
        duration,
        icon: 'warn',
        ...options
    };

    // 调用挂载节点函数
    onMount(options);

    // 显示一段时间后自动消失，默认2秒
    setTimeout(() => {
        inst.unmount(node);
        document.body.removeChild(node);
    }, duration);
}

// 显示tost-loading
const loading = (content = '', options = {}) => {
    options = {
        content: '请稍后...',
        icon: 'loading',
        ...options
    };

    // 调用挂载节点函数
    onMount(options);
}

// 隐藏Loading
const hidden = () => {
    if (inst) {
        inst.unmount(node); // 如果存在Vue实例，则卸载它
    }

    if (document.body.contains(node)) {
        document.body.removeChild(node); // 如果节点存在且已经添加到body中，则移除它
    }
}

const wxToast = {
    show,
    warn,
    loading,
    hidden
};

export { wxToast };
