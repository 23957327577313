import { request } from "../request.js";

export function loadUser(data={}){
    return request( {
        url : "loadUser",
        method : "get",
        data
    })
}

export function saveUserBasic(data={}){
    return request( {
        url : "saveUserBaseInfo",
        method : "post",
        data
    })
}
