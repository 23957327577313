import { ref, watchEffect } from 'vue';

const setRootFontSize = () => {
    // 创建一个ref来保存当前视口宽度
    const viewportWidth = ref(window.innerWidth);

    // 监听窗口大小变化，并更新viewportWidth的值
    watchEffect(() => {
        viewportWidth.value = window.innerWidth;
    });


    // 根据视口宽度设置HTML的字体大小 375 * (10 / 375) = 10 px
    watchEffect(() => {
        // console.log(viewportWidth.value);
        if (viewportWidth.value <= 767) {
            // 手机设备
            document.documentElement.style.fontSize = `${viewportWidth.value * (10 / 375)}px`;
        } else if (viewportWidth.value >= 768 && viewportWidth.value <= 1024) {
            // 平板设备
            document.documentElement.style.fontSize = `${viewportWidth.value * (10 / 768)}px`;
        } else {
            // 桌面设备
            document.documentElement.style.fontSize = `${viewportWidth.value * (10 / 1680)}px`;
        }
    });
};

const wd = {
    setRootFontSize
};

export { wd };