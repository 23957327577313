const useCarRoute = [
    {
        path: '/usecar/travel.htm',
        name: 'usercarTravel',
        meta: { isAuth: true},
        component: () => import('../../views/wx/usecar/travel.vue'),
    },
    {
        path: '/usecar/index.htm',
        name: 'usercarIndex',
        meta: { isAuth: true},
        component: () => import('../../views/wx/usecar/index.vue'),
    }
]

export default useCarRoute