<template>
    <div class="" role="alert">
        <div class="weui-mask_transparent"></div>
        <div class="weui-toast">
            <!-- 成功 -->
            <i v-if="icon == 'success'" class="weui-icon_toast weui-icon-success-no-circle"></i>
            <!-- 失败 -->
            <i v-if="icon == 'warn'" class="weui-icon_toast weui-icon-warn"></i>
            <!-- 加载 -->
            <span v-if="icon == 'loading'" class="weui-primary-loading weui-icon_toast">
                <span class="weui-primary-loading__dot"></span>
            </span>
            <p class="weui-toast__content">{{content}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            content: {
                type: String,
                default: '成功!'
            },
            icon: {
                type: String,
                default: 'success'
            },
            loading: {
                type: Boolean,
                default: true
            }
        }
    };
</script>

<style scoped>
    @import '@/assets/plugins/weui/weui.css';

    .weui-toast__content {
        font-size: 16px;
    }
</style>