const cslUserRoute = [
    {
        path: '/csl/user/index.htm',
        name: 'userIndex',
        component: () => import('../../views/csl/user/index.vue'),
    },
    {
        path: '/csl/user/tickets.htm',
        name: 'userTickets',
        component: () => import('../../views/csl/user/tickets.vue'),
    },
    {
        path: '/csl/user/exchange.htm',
        name: 'userExchange',
        component: () => import('../../views/csl/user/exchange.vue'),
    },
    {
        path: '/csl/user/test.htm',
        name: 'userttt',
        component: () => import('../../views/csl/user/test.vue'),
    },
    {
        path: '/csl/user/upload.htm',
        name: '/csluserUpload',
        // meta: { isAuth: true },
        component: () => import('../../views/csl/user/upload.vue')
    }
]

export default cslUserRoute